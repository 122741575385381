export const HEADER_HEIGHT = 92;

export const GNB_HEIGHT = 0;

export const OFFLINE_STORAGE_SIMULATOR_HEADER_HEIGHT = 61;

export const BREAD_CRUMB_HEIGHT = 24;

export const LAYOUT_MAX_WIDTH = 1170;

export const LAYOUT_MIN_WIDTH = 970;

export const FOOTER_FULL_HEIGHT = 412;

export const ARROW_UP_BUTTON_SCROLL_HEIGHT = 327;

export const BUDGET_EXPECT_BOTTOM_SHEET_HEIGHT = 128;

export const FAVORITE_TEST_BOTTOM_SHEET_HEIGHT = 112;

export const UNTACT_CONSULT_BOTTOM_SHEET_HEIGHT = 112;

export const EVENT_DETAIL_BOTTOM_SHEET_HEIGHT = 112;

export const FORM_WIDTH = 570;

// zIndex
export const BASE_Z_INDEX = 9;

export const MAX_Z_INDEX = 9999;

export const HEADER_Z_INDEX = 1050;

export const DRAWER_Z_INDEX = 1100;

export const POPUP_Z_INDEX = MAX_Z_INDEX - 100;

export const TOAST_Z_INDEX = MAX_Z_INDEX;

export const ALERT_Z_INDEX = MAX_Z_INDEX;

export const CONFIRM_Z_INDEX = MAX_Z_INDEX;

export const TOP_BUTTON_Z_INDEX = HEADER_Z_INDEX;

export const LAYOUT_OVER_WIDTH = 1400;

export const BREAD_CRUMB_Z_INDEX = 1000;
